import _pickNonNull2 from "../../pick-non-null.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllAddonProviders = getAllAddonProviders;
exports.getAddonProvider = getAddonProvider;
exports.getAvailableInstances = getAvailableInstances;
exports.getCreditPrice = getCreditPrice;
exports.getAllZones = getAllZones;
var _pickNonNull = _pickNonNull2;
/**
 * GET /products/addonproviders
 * @param {Object} params
 * @param {String} params.orgaId
 */

function getAllAddonProviders(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/products/addonproviders`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["orgaId"]) // no body

  });
}
/**
 * GET /products/addonproviders/{provider_id}
 * @param {Object} params
 * @param {String} params.provider_id
 * @param {String} params.orgaId
 */


function getAddonProvider(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/products/addonproviders/${params.provider_id}`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["orgaId"]) // no body

  });
}
/**
 * GET /products/instances
 * @param {Object} params
 * @param {String} params.for
 */


function getAvailableInstances(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/products/instances`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["for"]) // no body

  });
}
/**
 * GET /products/prices
 * @param {Object} params
 */


function getCreditPrice() {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/products/prices`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /products/zones
 * @param {Object} params
 */


function getAllZones() {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/products/zones`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}

export default exports;
export const __esModule = exports.__esModule;
const _getAllAddonProviders = exports.getAllAddonProviders,
      _getAddonProvider = exports.getAddonProvider,
      _getAvailableInstances = exports.getAvailableInstances,
      _getCreditPrice = exports.getCreditPrice,
      _getAllZones = exports.getAllZones;
export { _getAllAddonProviders as getAllAddonProviders, _getAddonProvider as getAddonProvider, _getAvailableInstances as getAvailableInstances, _getCreditPrice as getCreditPrice, _getAllZones as getAllZones };